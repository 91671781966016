const init = () => {
  const scrollAnimationEffect = (querySelector, activeClass, stepWiseDelay) => {
    const elements = document.querySelectorAll(querySelector);

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4,
    };

    const callbacks = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(activeClass);
        }
      });
    };

    let observer = new IntersectionObserver(callbacks, options);

    let echo = 0;
    elements.forEach((element) => {
      observer.observe(element);
      echo += stepWiseDelay;

      element.style.transitionDelay = stepWiseDelay ? `${echo}ms` : 0;
    });
  };

  const smoothScroll = () => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  };

  smoothScroll();
  scrollAnimationEffect(".fade-in", "active-fade-in");
  scrollAnimationEffect(
    ".skill-block.blur-fade-in",
    "active-blur-fade-in",
    500
  );
};
//

init();